import { axiosNode } from '../api/axios';

const useInventory = () => {

    const uploadInventory = async (file: any, apiUrl: string): Promise<any> => {
        let formData = new FormData();
        formData.append("file", file);

        return await axiosNode.post<Promise<any>>(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => res)
            .catch(err => err)
    }


    return {
        uploadInventory,
    }

}

export default useInventory;
