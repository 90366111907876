import React from 'react'
import { Box, Card, CardContent, Typography, TextField, Button } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DataGrid, esES, GridColDef } from '@mui/x-data-grid';
import { IProductConfig } from '../../api/models/response/IProductResponse';

type Props = {
    title: string,
    rows: IProductConfig[]
    handleCreate: (arg: { name: string }) => void
}

const validationSchema = yup.object({
    name: yup.string().min(1).required('Campo requerido')
})


const ConfigCreator = ({ title, rows, handleCreate }: Props) => {

    const initialValues = { name: '' }
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            handleCreate(values)
            resetForm()
        },
        enableReinitialize: true,
        validationSchema: validationSchema
    })

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nombre',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            disableColumnMenu: true,
        }
    ];

    return (
        <Box mt={2} >
            <Card>
                <CardContent>
                    <Typography variant='h5'>
                        {title}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display='flex'
                            alignItems='start'
                            justifyContent='center'
                        >
                            <TextField
                                fullWidth
                                size='small'
                                sx={{ my: 2 }}
                                name='name'
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={(formik.touched.name && formik.errors.name) || " "}
                            />
                            <Button
                                color='primary'
                                variant='contained'
                                sx={{ ml: 2, my: 2 }}
                                type='submit'
                            >
                                Crear
                            </Button>
                        </Box>
                    </form>
                    <Box sx={{
                        height: 400,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingx: 2
                    }}>
                        <DataGrid
                            columns={columns}
                            rows={rows.map((row: IProductConfig, i: number) => ({ ...row, id: row._id }))}
                            className='conexsur-data-grid'
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default ConfigCreator
