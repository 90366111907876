/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewHeader from "../../components/layout/ViewHeader";
import OrderClient from "./OrderClient";
import OrderItems from "./OrderItems";
import { useFormik } from "formik";
import validationSchema from "./ordersValidationSchema";
import { Box } from "@mui/system";
import { Button, CircularProgress } from "@mui/material";
import useOrders from "../../hooks/useOrders";
import { convertOrder } from "./utils";
import OrderStatusSteps from "./OrderStatusSteps";
import useUsers from "../../hooks/useUsers";
import { ERoles } from "../../constants/users/users";

type Props = {};

const initialValues = {
  stateOrdern: "Borrador",
  city: "",
  department: "",
  priceType: "MAYORISTA",
  total: 0,
  term: 0,
  financialDiscount: 0,
  client: "",
  clientMail: "",
  paymentMethod: "",
  invoiceType: "PEDIDO",
  date: new Date().toISOString().split("T")[0],
  origin: "01",
  addres: "",
  phone: "",
  destination: "01",
  operationNumber: 0,
  documentType: "PEDIDO",
  shoes: [],
};

const OrdersForm = (props: Props) => {
  const { id } = useParams();
  const { createOrder, getOrderById, editOrder, updateOrderState } = useOrders();
  const { hasRoleAccess } = useUsers();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    fetchOrder();
  }, [id]);

  const fetchOrder = async () => {
    if (id) {
      setisLoading(true);
      getOrderById(id).then((res) => {
        const formattedDate = new Date(res.dateCreated)
          .toISOString()
          .split("T")[0];
        formik.setValues({
          ...res,
          client: res.client._id,
          date: formattedDate,
          city: res.city._id,
          department: res.department._id,
          shoes: res.shoes.map((shoe: any) => {
            return {
              ...shoe,
              color: shoe.shoeId.color_id.name,
              size: shoe.shoeId.size_id.name,
              brand: shoe.shoeId.brand_id.name,
            };
          }),
        });
        setisLoading(false);
      });
    }
  };

  const handleSubmit = async () => {
    const formatedOrder = convertOrder(formik.values);
    if (id) {
      editOrder(id, formatedOrder);
    } else {
      createOrder(formatedOrder).then((res) => formik.resetForm());
    }
  };

  const handleRejectOrder = async () => {
    if (window.confirm("¿Está seguro de que desea rechazar la orden?") && id) {
      updateOrderState(id, 'Rechazado').then(() => fetchOrder());
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 5,
        }}
      >
        <ViewHeader text={id ? "Orden" : "Nueva orden"} />
        {hasRoleAccess([ERoles.ADMINISTRACION, ERoles.CARTERA]) && id && formik.values.stateOrdern !== 'Rechazado' && (
          <Button
            variant="contained"
            color="warning"
            size="large"
            onClick={handleRejectOrder}
          >
            Rechazar Orden
          </Button>
        )}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <>
          {id && formik.values.stateOrdern !== 'Rechazado' && <OrderStatusSteps activeStep={formik.values.stateOrdern} />}
          <form onSubmit={formik.handleSubmit}>
            <OrderClient formik={formik} isEdit={!!id} />
            <OrderItems formik={formik} />
            <Box
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {id ? "Actualizar" : "Crear"} Orden
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default OrdersForm;
