import { useDispatch } from 'react-redux'
import { apiUrls } from '../api/apiUrls'
import { setColors, setSizes, setBrands } from '../store/products/productsSlice'
import useNotify from './useNotify'
import { axiosNode } from '../api/axios'


const useProducts = () => {
    const { showMessage } = useNotify()
    const dispatch = useDispatch();

    const getProductsColors = async () => {
        return await axiosNode.post(apiUrls.products.GetProductsColors, JSON.stringify({
            query: { name: "" }
        }))
            .then(res => {
                if (res.status !== 200) throw Error
                dispatch(setColors(res.data.results))
            })
            .catch(err => showMessage('Error cargando colores', 'error'))
    }

    const getProductsSizes = async () => {
        return await axiosNode.post(apiUrls.products.GetProductsSizes, JSON.stringify({
            query: { name: "" }
        }))
            .then(res => {
                if (res.status !== 200) throw Error
                dispatch(setSizes(res.data.results))
            })
            .catch(err => showMessage('Error cargando colores', 'error'))
    }

    const getProductsBrands = async () => {
        return await axiosNode.post(apiUrls.products.GetProductsBrands, JSON.stringify({
            query: { name: "" }
        }))
            .then(res => {
                if (res.status !== 200) throw Error
                dispatch(setBrands(res.data.results))
            })
            .catch(err => showMessage('Error cargando marcas', 'error'))
    }

    const createColor = async (colorData: { name: string }) => {
        return await axiosNode.post(apiUrls.products.PostProductsColors, colorData)
            .then(res => {
                if (res.status !== 200 || res.data.errors) throw res.data.errors ?? 'Error creando referencia';
                getProductsColors()
                showMessage('Color creado exitosamente', 'success')
            })
            .catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((err: any) => showMessage(err.msg, 'error'));
                } else {
                    showMessage('Error creando color', 'error');
                }
            })
    }

    const createSize = async (sizeData: { name: string }) => {
        try {
            const res = await axiosNode.post(apiUrls.products.PostProductsSizes, sizeData);
            if (res.status !== 200 || res.data.errors) throw res.data.errors ?? 'Error creando talla';
            await getProductsSizes();
            showMessage('Talla creada exitosamente', 'success');
        } catch (error) {
            if (Array.isArray(error)) {
                error.forEach((err: any) => showMessage(err.msg, 'error'));
            } else {
                showMessage('Error creando talla', 'error');
            }
        }
    }

    const createBrand = async (BrandData: { name: string }) => {
        return await axiosNode.post(apiUrls.products.PostProductsBrands, BrandData)
            .then(res => {
                if (res.status !== 200 || res.data.errors) throw res.data.errors ?? 'Error creando referencia';
                getProductsBrands()
                showMessage('Referencia creada exitosamente', 'success')
            })
            .catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((err: any) => showMessage(err.msg, 'error'));
                } else {
                    showMessage('Error creando referencia', 'error');
                }
            })
    }

    const findProduct = async (input: string) => {
        return await axiosNode.post(apiUrls.products.GetProductShoe, JSON.stringify({
            query: { description: input },
            values: { productBrand: "", productColor: "", productSize: "" }
        }))
            .then(res => res.data)
    }



    return {
        getProductsColors,
        createColor,
        getProductsSizes,
        createSize,
        createBrand,
        getProductsBrands,
        findProduct
    }
}

export default useProducts
