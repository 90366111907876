import { useState } from 'react';
import { apiUrls } from '../api/apiUrls'
import { useDispatch, useSelector } from 'react-redux';
import { setRegions } from '../store/config/configSlice';
import { setCurrentUser } from '../store/auth/authSlice';
import { IRootStore } from '../store/store';

import { IGeoCity } from '../api/models/response/IGeoResponse'

import useNotify from './useNotify';
import { axiosNode } from '../api/axios';

const useConfig = () => {
    const { showMessage } = useNotify()
    const dispatch = useDispatch();
    const cities = useSelector((state: IRootStore) => state.config.cities)
    const [isLoading, setisLoading] = useState<boolean>(false);

    const loadConfigs = async () => {
        setisLoading(true)
        let errors: Array<string> = [];
        await new Promise(r => setTimeout(r, 1500));
        try {
            const response = await axiosNode.get(apiUrls.security.GetCurrentUser)
            // if (!response.isSuccess) throw response.error;
            localStorage.setItem('currentUser', JSON.stringify(response.data.names))
            dispatch(setCurrentUser(response.data))


        } catch (err) {
            errors.push(err instanceof Error ? err.message : 'Error general cargando información del usuario')
        }
        // try {
        //     const response = await axiosPrivate.get(apiUrls.geo.GetCities)
        //     // if (!response.isSuccess) throw response.error;
        //     dispatch(setCities(response.data))


        // } catch (err) {
        //     errors.push(err instanceof Error ? err.message : 'Error general cargando ciudades')
        // }
        try {
            const geoQuery = {
                "query": { "name": "" }
            }
            const response = await axiosNode.post(apiUrls.geo.GetRegions, JSON.stringify(geoQuery))
            // if (!response.isSuccess) throw response.error;
            dispatch(setRegions(response.data.results))


        } catch (err) {
            errors.push(err instanceof Error ? err.message : 'Error general cargando ciudades')
        }
        // try {
        //     const response = await axiosPrivate.get(apiUrls.security.GetRoles)
        //     // if (!response.isSuccess) throw response.error;
        //     dispatch(setRoles(response.data.results))


        // } catch (err) {
        //     errors.push(err instanceof Error ? err.message : 'Error general cargando roles')
        // }
        setisLoading(false)
    }
    const getCityFromStore = (id: number) => {
        return cities.find((el: IGeoCity) => el.pk === id)
    }

    const getCitiesFromRegion = async (regionId: string, city: string) => {
        return axiosNode.post(apiUrls.geo.GetCities, { query: { name: city, region_id: regionId } })
            .then(response => {
                if (response.status !== 200) throw Error
                return response.data.results
            })
            .catch(err => showMessage('Error cargando ciudades', 'error'))
    }

    return {
        loadConfigs,
        getCityFromStore,
        isLoading,
        getCitiesFromRegion
    }

}

export default useConfig;
