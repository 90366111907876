import Login from '../pages/auth/Login'
import Inventory from '../pages/inventory/Inventory'
import Users from '../pages/users/Users'
import UsersForm from '../pages/users/UsersForm'
import Orders from '../pages/orders/Orders'
import OrdersForm from '../pages/orders/OrdersForm'
import Shoes from '../pages/shoes/Shoes'
import Clients from '../pages/clients/Clients'
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AttributionIcon from '@mui/icons-material/Attribution';
import ClientDetail from '../pages/clients/ClientDetail'
import { ERoles } from '../constants/users/users'

interface IRoute {
    route: string,
    name: string,
    component: React.ReactNode,
    access: Array<number>,
    icon?: React.ReactNode,
    showOnDrawer?: boolean,
}

interface IRoutes {
    login: IRoute,
    home: IRoute,
    users: IRoute,
    newUser: IRoute,
    editUser: IRoute,
    inventory: IRoute,
    orders: IRoute,
    newOrder: IRoute,
    editOrder: IRoute,
    shoes: IRoute,
    clients: IRoute,
    viewClient: IRoute
}

const routes: IRoutes = {
    login: {
        route: '/',
        component: <Login />,
        access: [ERoles.TODOS],
        name: 'Login'
    },
    home: {
        route: '/home',
        component: <Orders />,
        access: [ERoles.TODOS],
        name: 'Inicio',
        icon: <HomeIcon />,
        showOnDrawer: true
    },
    users: {
        route: '/users',
        component: <Users />,
        access: [ERoles.ADMINISTRACION, ERoles.CARTERA, ERoles.VENDEDOR],
        name: 'Usuarios',
        icon: <GroupsIcon />,
        showOnDrawer: true
    },
    newUser: {
        route: '/users/new',
        component: <UsersForm />,
        access: [ERoles.ADMINISTRACION],
        name: 'Nuevo Usuario',
    },
    editUser: {
        route: '/users/edit/:id',
        component: <UsersForm />,
        access: [ERoles.ADMINISTRACION],
        name: 'Editar Usuario',
    },
    inventory: {
        route: '/inventory',
        component: <Inventory />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.VENDEDOR, ERoles.FACTURACION, ERoles.BODEGA],
        name: 'Inventario',
        icon: <InventoryIcon />,
        showOnDrawer: true
    },
    shoes: {
        route: '/shoes',
        component: <Shoes />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.VENDEDOR, ERoles.FACTURACION, ERoles.BODEGA],
        name: 'Zapatos',
        icon: <SearchIcon />,
        showOnDrawer: true
    },
    clients: {
        route: '/clients',
        component: <Clients />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.CARTERA, ERoles.VENDEDOR, ERoles.FACTURACION],
        name: 'Clientes',
        icon: <AttributionIcon />,
        showOnDrawer: true
    },
    viewClient: {
        route: '/clients/:id',
        component: <ClientDetail />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.CARTERA, ERoles.VENDEDOR, ERoles.FACTURACION],
        name: 'Clientes',
    },
    orders: {
        route: '/orders',
        component: <Orders />,
        access: [ERoles.TODOS],
        name: 'Ordenes',
        icon: <ShoppingBasketIcon />,
        showOnDrawer: true
    },
    newOrder: {
        route: '/orders/new',
        component: <OrdersForm />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.VENDEDOR, ERoles.FACTURACION],
        name: 'Nueva Orden'
    },
    editOrder: {
        route: '/orders/:id',
        component: <OrdersForm />,
        access: [ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.VENDEDOR, ERoles.FACTURACION],
        name: 'Editar Orden'
    }
}

export default routes
export type { IRoute, IRoutes }
