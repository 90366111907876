
type Price = {
    _id: string;
    lista: string;
    price: string;
    porIva: string;
};

type Shoe = {
    _id: string;
    brand: string;
    color: string;
    size: string;
    quantity: string;
    prices: Price[];
    shoeId: string
    amountPeers: string;
};

interface InitialData {
    city: string;
    department: string;
    priceType: string;
    total: number;
    term: number;
    financialDiscount: number;
    client: string;
    clientMail: string;
    paymentMethod: string;
    invoiceType: string;
    date: string;
    origin: string;
    addres: string;
    phone: string;
    destination: string;
    operationNumber: number;
    documentType: string;
    shoes: (Shoe | ConvertedShoe)[];
}

interface ConvertedShoe {
    shoeId: string | { _id: string };
    shoePrriceId: string;
    amountPeers: number;
    discount: number;
    shoePrrice: string;
    shoePriceDiscount: string;
    totalPrice: string;
}

interface ConvertedData {
    client: string;
    date: string;
    origin: string;
    addres: string;
    city: string;
    phone: string;
    paymentMethod: string;
    destination: string;
    department: string;
    invoiceType: string;
    operationNumber: number;
    financialDiscount: number;
    term: number;
    documentType: string;
    priceType: string;
    clientMail: string;
    shoes: ConvertedShoe[];
}

function convertOrder(initialData: InitialData): ConvertedData {
    return {
        client: initialData.client,
        date: initialData.date,
        origin: initialData.origin,
        addres: initialData.addres,
        city: initialData.city,
        phone: initialData.phone,
        paymentMethod: initialData.paymentMethod,
        destination: initialData.destination,
        department: initialData.department,
        invoiceType: initialData.invoiceType,
        operationNumber: initialData.operationNumber,
        financialDiscount: initialData.financialDiscount,
        term: initialData.term,
        documentType: initialData.documentType,
        priceType: initialData.priceType,
        clientMail: initialData.clientMail,
        shoes: initialData.shoes
            .map(shoe => {
                if ('shoePrrice' in shoe) {
                    return shoe;
                }
                const shoePrice = shoe.prices.find(price => price.lista === getPricetypeId(initialData.priceType));
                const discount = initialData.financialDiscount;
                const shoePrrice = shoePrice ? Number(shoePrice.price).toLocaleString('es-CO') : '0';
                const totalPrice = shoePrice ? (parseFloat(shoePrice.price) * parseInt(shoe.amountPeers)).toLocaleString('es-CO') : '0';
                const discountAmount = shoePrice ? parseFloat(shoePrice.price) * (discount / 100) : 0;
                const discountedPrice = shoePrice ? (parseFloat(shoePrice.price) - discountAmount).toLocaleString('es-CO') : '0';
                return {
                    shoeId: shoe._id,
                    shoePrriceId: shoe._id,
                    amountPeers: parseInt(shoe.amountPeers),
                    discount: discount,
                    shoePrrice: shoePrrice,
                    shoePriceDiscount: discountedPrice,
                    totalPrice: totalPrice
                };
            })
    };
}

function getPricetypeId(typeString: string) {
    // ["PUBLICO","COMERCIO", "ESPECIAL"]
    switch (typeString) {
        case 'PUBLICO':
            return '01'
        case 'COMERCIO':
            return '02'
        case 'ESPECIAL':
            return '03'
    }
}


export { convertOrder, getPricetypeId }; export type { ConvertedData };

