import React from 'react';
import Chip from '@mui/material/Chip';
import { green, red, yellow, grey } from '@mui/material/colors';

interface StatusChipProps {
    status: string;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
    const getColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'aprobado':
                return green[500];
            case 'borrador':
                return grey[500];
            case 'rechazado':
                return red[500];
            default:
                return yellow[700];
        }
    };

    return (
        <Chip
            label={status}
            style={{ backgroundColor: getColor(status), color: 'white' }}
        />
    );
};

export default StatusChip;
